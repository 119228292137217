.instaswipe_navbar_brand {
  width: 180px;
}

.nav-background {
  background-color: #FFFFFF;
}

.navbar-copy {
  color: #FFFFFF;
}

.navigationLinks {
  color: #000000;
  font-weight: 600;
}

.navigationLinks:hover {
  color: cornflowerblue;
  text-decoration: none;
}

.cursorPointer:hover {
  color: #6099E2;
  text-decoration: none;
}

.row {
  flex-grow: 1
}

.navbar {
  padding: 1rem;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
}

.navbar-brand {
  margin: 0;
}

.navbar-toggler {
  padding: 0;
}

@media (max-width: 580px) {
  .instaswipe_navbar_brand {
    width: 120px;
  }
  .navbar {
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .instaswipe_navbar_brand {
    width: 100px;
  }
  .navbar {
    padding: 0.65rem;
  }
}
