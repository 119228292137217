.uploadDropZone {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  background-color: #e9ecef;
  border-radius: 5px;
  height: 200px;
}

.uploadIcon {
  width: 60px;
}

.greenSpinner {
  color: #28a745;
}

.highlight {
  background-color: #28a745;
}

.fileInput {
  display: none;
}
