.uploadError {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  background-color: #ef001e;
  border-radius: 5px;
}

.downloaded {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  background-color: #28a745;
  border-radius: 5px;
}
