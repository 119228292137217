body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f6fc;
}

h1, h2, h3, h4, h5 {
  display: inline-block;
  color: #212529;
  margin: 0;
  vertical-align: middle;
}

p {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.4rem;

}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

@media (max-width: 580px) {
  p {
    font-size: 0.9rem;
  }

  h1 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  h5 {
    font-size: 0.9rem;
  }

  h6 {
    font-size: 0.8rem;
  }
}

@media (max-width: 485px) {

  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 0.9rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  h5 {
    font-size: 0.8rem;
  }

  h6 {
    font-size: 0.7rem;
  }
}

@media (max-width: 425px) {

  h1 {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1rem;
  }

  h3 {
    font-size: 0.8rem;
  }

  h4 {
    font-size: 0.7rem;
  }

  h5 {
    font-size: 0.7rem;
  }

  h6 {
    font-size: 0.6rem;
  }
}
