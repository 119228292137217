/*
TODO import only necessary bootstrap files
*/
@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700');

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.btn-primary {
  border: none;
  background-color: #6099E2;
}

.btn-primary:hover {
  background-color: #003F91;
}